import Vue from "vue";
import { BootstrapVue } from 'bootstrap-vue'
import LoadScript from 'vue-plugin-load-script';
import App from '@/App.vue'
import { onError } from "apollo-link-error";

//router
import router from '@/routes';

//store
import store from '@/store';
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import VueCookie from 'vue-cookie'
import { ApolloLink } from 'apollo-link'
import * as helpers from './common/helpers.js';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/custom.scss';
import './scss/maintenance.scss'

import { GC_USER_ID, GC_AUTH_TOKEN } from './constants/settings';
let userId = localStorage.getItem(GC_USER_ID);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(GC_AUTH_TOKEN)

  operation.setContext({
    headers: {
      authorization: token ? `${token}` : null
    }
  })

  return forward(operation)

})

const plugin = {
  install(Vue) {
    Vue.prototype.$helpers = helpers; // we use $ because it's the Vue convention
  }
};

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.use(CoolLightBox);

Vue.use(VueCookie)
Vue.use(BootstrapVue)
Vue.use(LoadScript);
Vue.use(VueApollo)
Vue.use(plugin);
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL || "http://192.168.1.22:4000/",
})

const cache = new InMemoryCache()

const handleError = onError((data) => {
  const { result } = data?.networkError;
  const isClient = typeof window === "object";
  if (isClient && result?.status === 503 && result?.data?.status === "in_period" && window.location.pathname !== "/maintenance") {
      localStorage.setItem("DATA_MAINTENANCE", JSON.stringify(result?.data));
      localStorage.removeItem("IS_CLOSE_MODAL_MAINTENANCE")
      window.location.href = `//${window.location.host}/maintenance`;
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([handleError, authMiddleware.concat(httpLink)]),
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

new Vue({
  el: '#app',
  store,
  router,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
Vue.prototype.$userData = userId;